import React from 'react'
import { Link } from 'gatsby'
import cn from 'classnames'

import './index.css'

import AuthorCard from '../author-card'
import RedLine from '../red-line'
import ProjectImage from '../project-image'

const ResourceBlogPost = ({
  hero = false,
  title,
  url = '/',
  children,
  cover,
  authorNickname,
  authorName,
  authorPhoto,
  // aslında blog gönderileri yalnızca tek bir kategori alıyorlar ama biz
  // bu bileşeni /resources/seo gibi kategori sayfalarında da kullandığımız
  // için birden fazla kategoriyi kabul ediyoruz. çünkü diğer üç temel
  // içerik tipi birden fazla kategori alıyor.
  categoryBase,
  categories,
  locale
}) => {
  return (
    <article
      className={cn('resource-blog-post d-flex flex-column mb-32 px-32', {
        'resource-blog-post--normal-layout': !hero,
        'resource-blog-post--hero-layout': hero
      })}
    >
      <figure className="resource-blog-post-cover mb-24">
        <Link to={url}>{cover && <ProjectImage image={cover} />}</Link>
      </figure>

      <div className="resource-blog-post-body">
        <header>
          <h4>
            <Link className="link-hover" to={url}>
              {title}
            </Link>
          </h4>
          <div className="mt-24">{children}</div>
        </header>
        <footer className="mt-16">
          <RedLine />
          <div className="mt-24">
            <AuthorCard
              categoryBase={categoryBase}
              categories={categories}
              locale={locale}
              nickname={authorNickname}
              name={authorName}
              photo={authorPhoto}
            />
          </div>
        </footer>
      </div>
    </article>
  )
}

export default ResourceBlogPost
